import React, { useState, useRef, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useChat } from '../context/ChatContext';
import { ArrowUp } from 'lucide-react';

export default function Solver() {
  const { chatMessages, setChatMessages } = useChat();
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstQuery, setIsFirstQuery] = useState(true);
  const chatEndRef = useRef(null);
  const { hasPaid } = useContext(AuthContext);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSendMessage = async () => {
    if (!hasPaid) {
      alert("Only paid users have access to this feature. Please  upgrade your plan.");
      return;
    }

    if (userInput.trim() === '') return;

    // Add user message to chat
    const newMessages = [...chatMessages, { sender: 'user', text: userInput }];
    setChatMessages(newMessages);
    setUserInput('');
    setIsLoading(true);
    
    if (isFirstQuery) {
      setIsFirstQuery(false);
    }

    try {
      const response = await fetch(
        "https://generatetutorresponse-ibj6fdglpa-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ 
            prompt: userInput,
            history: chatMessages.map(msg => ({
              role: msg.sender === 'bot' ? 'assistant' : 'user',
              content: msg.text
            }))
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching response");
      }

      const data = await response.json();
      setChatMessages(prev => [...prev, { sender: 'bot', text: data.explanation }]);
    } catch (error) {
      console.error("Error communicating with solver:", error);
      setChatMessages(prev => [
        ...prev,
        { sender: 'bot', text: "I'm sorry, I couldn't process that. Please try again." }
      ]);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      {isFirstQuery ? (
        <div className="flex-1 flex flex-col items-center justify-center px-4">
          <h1 className="text-4xl font-medium text-gray-900 mb-8">
            What should we learn today?
          </h1>
          <div className="w-full max-w-3xl">
            <div className="flex items-center gap-4 bg-white rounded-xl shadow-lg border border-gray-200 hover:border-gray-300 transition-colors p-2">
              <textarea
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Ask anything..."
                className="flex-1 resize-none p-3 bg-transparent border-none focus:outline-none focus:ring-0 min-h-[24px] max-h-[200px] overflow-y-auto"
                style={{
                  height: 'auto',
                  minHeight: '24px',
                  maxHeight: '200px'
                }}
              />
              <button
                onClick={handleSendMessage}
                disabled={isLoading}
                className="p-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors h-fit"
              >
                <ArrowUp className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 overflow-y-auto">
          <div className="max-w-3xl mx-auto p-4">
            {chatMessages.map((msg, idx) => (
              <div
                key={idx}
                className={`flex ${
                  msg.sender === 'bot' ? 'justify-start' : 'justify-end'
                }`}
              >
                <div
                  className={`px-4 py-2 rounded-lg max-w-[80%] ${
                    msg.sender === 'bot'
                      ? 'text-gray-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  <span className="whitespace-pre-wrap">{msg.text}</span>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start">
                <div className="px-4 py-2 rounded-lg flex items-center space-x-1">
                  <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                  <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                  <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                </div>
              </div>
            )}
            <div ref={chatEndRef} />
          </div>
          
          <div className="fixed bottom-0 left-0 right-0 px-4 pb-6 pt-2">
            <div className="max-w-3xl mx-auto">
              <div className="flex items-center gap-4 bg-white rounded-xl shadow-lg border border-gray-200 hover:border-gray-300 transition-colors p-2">
                <textarea
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Ask anything..."
                  className="flex-1 resize-none p-3 bg-transparent border-none focus:outline-none focus:ring-0 min-h-[24px] max-h-[200px] overflow-y-auto"
                  style={{
                    height: 'auto',
                    minHeight: '24px',
                    maxHeight: '200px'
                  }}
                />
                <button
                  onClick={handleSendMessage}
                  disabled={isLoading}
                  className="p-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors h-fit"
                >
                  <ArrowUp className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}