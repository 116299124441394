"use client";

import React, { useState, useContext, useEffect, useRef } from "react";
import { MathJaxContext, MathJax } from "better-react-mathjax";
import { doc, arrayUnion, setDoc } from "firebase/firestore";
import { db } from "../utils/firebase"; // Ensure you have the correct import
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

import {
  MessageSquare,
  ArrowRight,
  RotateCcw,
  CheckCircle2,
  XCircle,
  ArrowUp,
} from "lucide-react";

const mathJaxConfig = {
  tex: {
    inlineMath: [["\\(", "\\)"]],
    displayMath: [["\\[", "\\]"]],
    processEscapes: true,
  },
  startup: {
    typeset: false,
  },
};

export default function SingleQuiz({ lesson, lessonId, sectionId }) {
  const navigate = useNavigate(); // Initialize useNavigate

  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [completedParts, setCompletedParts] = useState(new Set());
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [showAiChat, setShowAiChat] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);

  const { userProgress, setUserProgress } = useContext(DataContext);
  const { user, hasPaid } = useContext(AuthContext);

  const currentPart = lesson.parts[currentPartIndex]; // includes data on 4 things including question 
  const totalParts = lesson.parts.length;

  const [isMac, setIsMac] = useState(false);

  useEffect(() => {
    const platform = navigator.platform.toLowerCase();
    setIsMac(platform.includes("mac") || platform.includes("darwin"));
  }, []);

  const [showExitPopup, setShowExitPopup] = useState(false); // Manage modal visibility

  const handleExitClick = () => {
    // If on first part (index 0) and no progress, exit immediately
    if (currentPartIndex === 0 && !isAnswered) {
      navigate(-1); // Go back directly
      return;
    }

    // Otherwise show the confirmation popup
    setShowExitPopup(true);
  };

  const handleStay = () => {
    setShowExitPopup(false);
  };

  const handleQuit = () => {
    setShowExitPopup(false);
    navigate(-1);
  };

  // Render dynamic shortcut
  const renderShortcut = () => (
    <div className="flex items-center text-xs px-2 py-1 rounded-md">
      {isMac ? (
        <>
          <span className="font-bold">⌘</span>
          <span className="mx-1">+</span>
          <span className="font-bold">↩</span>
        </>
      ) : (
        <>
          <span className="font-bold">Ctrl</span>
          <span className="mx-1">+</span>
          <span className="font-bold">Enter</span>
        </>
      )}
    </div>
  );

  /* -------------------------
   * Firestore Progress Update
   * ------------------------*/
  const markLessonAsComplete = async () => {
    try {
      const userId = user.uid;
      const progressRef = doc(db, `users/${userId}/progress`, sectionId);

      // Use setDoc to create the document if it doesn't exist
      await setDoc(
        progressRef,
        {
          completedLessons: arrayUnion(lessonId), // Add the lesson ID to the completedLessons array
        },
        { merge: true } // Merge with existing fields if the document already exists
      );

      // Update local progress state
      setUserProgress((prev) => ({
        ...prev,
        [sectionId]: [...new Set([...(prev[sectionId] || []), lessonId])],
      }));

      console.log("Lesson marked as complete!");
      // Removed automatic navigation
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  };

  /* -------------------------
   * Navigation & State Logic
   * ------------------------*/
  const handlePartClick = (index) => {
    // Only navigate to completed parts
    if (completedParts.has(index)) {
      setCurrentPartIndex(index);
      resetQuizState();
    }
  };

  const resetQuizState = () => {
    setSelectedAnswer(null);
    setIsAnswered(false);
    setIsCorrect(false);
    setShowExplanation(false);
  };

  const handleSelectOption = (index) => {
    if (isCorrect) return; // Prevent selection if already correct
    if (isAnswered && !isCorrect) {
      resetQuizState();
    }
    setSelectedAnswer(index);
  };

  const handleCheckAnswer = () => {
    if (selectedAnswer === null) return;
    setIsAnswered(true);
    const isRight = selectedAnswer === currentPart.correctAnswer;
    setIsCorrect(isRight);

    // If correct, reveal explanation and mark part as completed
    if (isRight) {
      setShowExplanation(true);
      setCompletedParts((prev) => new Set(prev.add(currentPartIndex)));
    }
  };

  const handleTryAgain = () => {
    resetQuizState();
  };

  const handleContinue = () => {
    // If there are more parts left, go to the next
    if (currentPartIndex < totalParts - 1) {
      setCurrentPartIndex((prev) => prev + 1);
      resetQuizState();
    } else {
      // If this was the last part, show the final "Congrats" screen
      setShowCongrats(true);
    }
  };

  /* -------------------------
   * Dynamic Layout Classes
   * ------------------------*/
  const getAiChatClasses = () => {
    if (!showAiChat) return "hidden";
    return showExplanation ? "w-1/3" : "w-1/2";
  };

  const getQuizClasses = () => {
    // Reduced p-6 to p-4 if you want less overall padding:
    const base = "overflow-y-auto mt-4 bg-white p-4";
    if (showAiChat && showExplanation) {
      return `${base} w-1/3 mx-4`;
    } else if (showAiChat || showExplanation) {
      return `${base} w-1/2 mx-4`;
    }
    return `${base} w-full max-w-2xl mx-auto`;
  };

  const getExplanationClasses = () => {
    if (!showExplanation) return "hidden";
    return showAiChat ? "w-1/3" : "w-1/2";
  };

  /* -------------------------
   * Explanation Rendering
   * ------------------------*/
  function renderExplanationItem(exp, i) {
    switch (exp.type) {
      case "heading":
        return (
          <MathJax key={i}>
            <p className="font-inter font-semibold text-base mt-3 mb-2">{exp.data}</p>
          </MathJax>
        );
      case "text":
        return (
          <MathJax key={i}>
            <p className="font-inter text-gray-700 mb-2">{exp.data}</p>
          </MathJax>
        );
      case "final":
        return (
          <MathJax key={i}>
            <p className="font-inter text-green-700 font-semibold mb-2">
              {exp.data}
            </p>
          </MathJax>
        );
      default:
        return (
          <MathJax key={i}>
            <p className="font-inter text-gray-700 mb-2">{exp.data}</p>
          </MathJax>
        );
    }
  }

  /* -------------------------
   * Keyboard Event Handlers
   * ------------------------*/
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Handle Command + Enter or Control + Enter
      if (
        (event.metaKey || event.ctrlKey) &&
        (event.key === "Enter" || event.key === "Return")
      ) {
        event.preventDefault();
        if (!isAnswered) {
          handleCheckAnswer();
        } else if (!isCorrect) {
          handleTryAgain();
        } else if (isCorrect) {
          handleContinue();
        }
      }

      // Handle Number Keys (1-9)
      const numberKey = parseInt(event.key, 10);
      if (
        numberKey >= 1 &&
        numberKey <= currentPart.options.length &&
        !isAnswered &&
        !isCorrect // Prevent selection if correct
      ) {
        event.preventDefault();
        handleSelectOption(numberKey - 1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isAnswered, isCorrect, selectedAnswer, currentPart]);

  /* -------------------------
   * AI Chat Functionality
   * ------------------------*/
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, showAiChat]);

  const [isFirstMessage, setIsFirstMessage] = useState(true);

  const handleSendMessage = async () => {
    if (!hasPaid) {
      alert("Only paid users have access to this feature. Please upgrade your plan.");
      return;
    }

    if (userInput.trim() === "") return;

    const newMessages = [...chatMessages, { sender: "user", text: userInput }];
    setChatMessages(newMessages);
    setUserInput("");
    setIsLoading(true);
    
    if (isFirstMessage) {
      setIsFirstMessage(false);
    }

    // This prompt will be sent to your generateTutorResponse function 
    const promptString = `
Question: ${currentPart.question}

Options:
${currentPart.options.map((opt, i) => `${i + 1}. ${opt}`).join("\n")}

User's message:
${userInput}
    `;

    console.log("Sending prompt to generateTutorResponse:", promptString);

    try {
      // Send the prompt string using fetch
      const response = await fetch(
        "https://generatetutorresponse-ibj6fdglpa-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: promptString }),
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching tutor response");
      }

      const data = await response.json();
      const tutorReply = data.explanation;

      setChatMessages((prev) => [...prev, { sender: "tutor", text: tutorReply }]);
    } catch (error) {
      console.error("Error communicating with tutor:", error);
      setChatMessages((prev) => [
        ...prev,
        { sender: "tutor", text: "I'm sorry, I couldn't process that. Please try again." },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  /* -------------------------
   * useRef to Track Completion Status
   * ------------------------*/
  const hasMarkedComplete = useRef(false);

  /* -------------------------
   * useEffect to Automatically Mark Completion
   * ------------------------*/
  useEffect(() => {
    if (showCongrats && !hasMarkedComplete.current) {
      markLessonAsComplete();
      hasMarkedComplete.current = true; // Ensure it only runs once
    }
  }, [showCongrats]);

  /* -------------------------
   * Render
   * ------------------------*/
  useEffect(() => {
    window.MathJax = {
      tex: {
        inlineMath: [['$', '$'], ['\\(', '\\)']],
        displayMath: [['$$', '$$'], ['\\[', '\\]']],
        processEscapes: true,
      },
      svg: {
        fontCache: 'global'
      }
    };
  }, []);

  return (
    <MathJaxContext config={mathJaxConfig}>
      <div className="flex flex-col h-screen">
        {/* Top Header (Progress Bar) */}
        <header className="w-full border-b border-gray-200 bg-white py-6 px-6">
          <div className="relative flex justify-center items-center">
            <button
              onClick={handleExitClick} // Show popup instead of navigating directly
              className="absolute left-0 text-gray-600 hover:text-gray-800 flex items-center gap-2"
            >
              <span className="text-xl font-bold">&times;</span>
              <span className="sr-only">Exit</span>
            </button>

            <div className="flex justify-center items-center space-x-2">
              {lesson.parts.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePartClick(index)}
                  className={`w-16 h-2 rounded-full ${
                    currentPartIndex === index
                      ? "bg-teal-600"
                      : completedParts.has(index)
                      ? "bg-teal-500"
                      : "bg-gray-200"
                  }`}
                />
              ))}
            </div>
          </div>
        </header>

        {/* Exit Confirmation Popup */}
        {showExitPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
              <h2 className="text-xl font-bold text-center mb-4">
                Are you sure you want to quit?
              </h2>
              <p className="text-gray-600 text-center mb-6">
                All progress in this session will be lost
              </p>
              <div className="mt-4 flex justify-between">
                <button
                  onClick={handleStay}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                >
                  Stay
                </button>
                <button
                  onClick={handleQuit}
                  className="px-4 py-2 text-sm font-medium text-white bg-black hover:bg-gray-800 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                >
                  Quit
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="flex-1 w-full px-4 gap-4 flex overflow-y-auto pb-24">
          {showCongrats ? (
            /* FINAL "CONGRATS" SCREEN */
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                Congratulations!
              </h1>
              <p className="text-gray-700 mb-4">
                You've completed all parts!
              </p>
              {/* Added "Go Back" Button */}
              <button
                onClick={() => navigate(-1)} // Navigate back when clicked
                className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-md"
              >
                Go Back
              </button>
            </div>
          ) : (
            /* NORMAL QUIZ VIEW */
            <>
              {/* Left (AI Chat) */}
              <div
                className={`border border-gray-200 rounded-lg mt-4 overflow-y-auto mx-auto max-w-3xl ${getAiChatClasses()}`}
              >
                <div className="p-4 flex flex-col h-full">
                  <div className="flex justify-between items-center mb-4">
                    <div className="w-full text-center">
                      <h2 className="text-lg font-bold">ai chat v1</h2>
                    </div>
                  </div>
                  
                  {/* Chat messages container */}
                  <div className="flex-1 overflow-y-auto mb-4 px-4">
                    {chatMessages.map((msg, idx) => (
                      <div
                        key={idx}
                        className={`mb-6 flex ${
                          msg.sender === "tutor" ? "justify-start" : "justify-end"
                        }`}
                      >
                        <div
                          className={`px-4 py-2 rounded-lg max-w-[80%] ${
                            msg.sender === "tutor"
                              ? "text-gray-800"
                              : "bg-gray-100 text-gray-800"
                          }`}
                        >
                          <MathJax>
                            <span className="text-sm font-normal whitespace-pre-wrap">
                              {msg.text}
                            </span>
                          </MathJax>
                        </div>
                      </div>
                    ))}
                  </div>
                  
                  {/* Input area with arrow icon */}
                  <div className={`mt-auto transition-all duration-300 `}>
                    <div className="flex items-center gap-2">
                      <textarea
                        value={userInput}
                        onChange={(e) => {
                          e.target.style.height = 'inherit';
                          e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`;
                          setUserInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                            e.preventDefault();
                            handleSendMessage();
                          }
                        }}
                        className="flex-1 border border-gray-200 rounded-lg p-2 resize-none focus:outline-none focus:ring-1 focus:ring-gray-300 min-h-[80px] max-h-[200px] overflow-y-auto"
                        placeholder="Ask your question... (Cmd + Enter to send)"
                        style={{
                          height: '80px',
                          minHeight: '80px',
                          maxHeight: '200px'
                        }}
                      />
                      <button
                        onClick={handleSendMessage}
                        className="p-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors h-fit"
                        disabled={isLoading}
                      >
                        <ArrowUp className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Center (Main Quiz) */}
              <div className={getQuizClasses()}>
                <div className="mb-3">
                  <MathJax>
                    <p className="text-lg mb-2">{currentPart.question}</p>
                  </MathJax>
                  {currentPart.img && (
                    <div className="mb-3">
                      <img
                        src={currentPart.img}
                        alt="Practice question illustration"
                        className="rounded-lg object-contain max-h-[35vh] w-auto mx-auto"
                      />
                    </div>
                  )}
                </div>

                {/* Options */}
                <div className="space-y-2 mb-4">
                  {currentPart.options.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => handleSelectOption(index)}
                      aria-disabled={isCorrect} // Optional: Accessibility
                      className={`w-full text-left text-sm p-2 border rounded-lg flex justify-between items-center ${
                        selectedAnswer === index
                          ? "border-teal-500 text-gray-800" // Updated for teal border and no purple background
                          : "border-gray-200 text-gray-700 hover:border-gray-300"
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <MathJax>
                          <span>{option}</span>
                        </MathJax>
                      </div>
                      <div className="bg-white border border-gray-300 text-gray-700 rounded px-2 py-1 text-xs mr-2">
                        {index + 1}
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              {/* Right (Explanation) */}
              <div
                className={`border border-gray-200 rounded-lg mt-4 overflow-y-auto ${getExplanationClasses()}`}
              >
                <div className="p-4">
                  <h2 className="font-bold text-lg mb-3">Explanation</h2>
                  <div className="space-y-2">
                    {currentPart.explanation.map((exp, i) =>
                      renderExplanationItem(exp, i)
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Footer (Buttons) */}
        {!showCongrats && (
          <footer
            className={`border-t border-gray-200 bg-white px-6 fixed bottom-0 left-0 w-full z-10 ${
              isAnswered
                ? isCorrect
                  ? "border-t-4 border-green-500"
                  : "border-t-4 border-red-500"
                : ""
            }`}
          >
            <div className="py-6 flex justify-between items-center">
              {/* Left: AI Chat Button */}
              <button
                onClick={() => setShowAiChat(true)}
                className="border border-black text-black hover:bg-gray-50 px-4 py-2 rounded-md flex items-center justify-center gap-2"
              >
                <MessageSquare className="w-4 h-4" />
                AI Chat
              </button>

              {/* Right: Check / Try Again / Continue */}
              {!isAnswered ? (
                <button
                  onClick={handleCheckAnswer}
                  className="border border-teal-500 text-teal-500 hover:bg-teal-50 px-4 py-2 rounded-md flex items-center justify-center gap-2"
                >
                  Check
                  {renderShortcut()}
                </button>
              ) : !isCorrect ? (
                <button
                  onClick={handleTryAgain}
                  className="border border-teal-500 text-teal-500 hover:bg-teal-50 px-4 py-2 rounded-md flex items-center justify-center gap-2"
                >
                  <RotateCcw className="w-4 h-4 mr-2" />
                  Try Again
                  {renderShortcut()}
                </button>
              ) : (
                <button
                  onClick={handleContinue}
                  className="border border-teal-500 text-teal-500 hover:bg-teal-50 px-4 py-2 rounded-md flex items-center justify-center gap-2"
                >
                  Continue
                  {renderShortcut()}
                  <ArrowRight className="w-4 h-4" />
                </button>
              )}
            </div>
          </footer>
        )}
      </div>
    </MathJaxContext>
  );
}
