// src/components/Signup.js

import React, { useState } from 'react';
import { auth, db } from '../utils/firebase'; // Ensure Firestore (db) is imported from your Firebase config
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { useNavigate, Link } from 'react-router-dom';

export default function Signup() {
  // Form state
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Handle standard email/password sign-up
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create a new user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update the user's display name in Firebase Auth
      if (name.trim() !== '') {
        await updateProfile(user, { displayName: name });
      }

      // Store additional user info in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name: name.trim(),
        email: email.trim(),
        hasPaid: false, // Initialize other fields as needed
        createdAt: new Date()
      });

      console.log('User created:', user);
      navigate('/dashboard/learn');
    } catch (error) {
      console.error('Error signing up with email/password:', error);
      // Optionally, handle error messages to display to the user
      alert(`Sign Up Error: ${error.message}`);
    }
  };

  // Handle Google sign-up
  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const additionalUserInfo = getAdditionalUserInfo(result);

      // Check if the user is new
      if (additionalUserInfo.isNewUser) {
        // Store additional user info in Firestore
        await setDoc(doc(db, 'users', user.uid), {
          name: user.displayName || '',
          email: user.email || '',
          hasPaid: false,
          createdAt: new Date()
        });
      }

      console.log('Google sign-up result:', user);
      navigate('/dashboard/learn');
    } catch (error) {
      console.error('Error signing up with Google:', error);
      // Optionally, handle error messages to display to the user
      alert(`Google Sign Up Error: ${error.message}`);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        {/* Logo and Heading */}
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="https://tailwindui.com/plus-assets/img/logos/mark.svg?color=indigo&shade=600"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold tracking-tight text-gray-900">
            Create your account
          </h2>
        </div>

        {/* Signup Form */}
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name (optional) */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                Name (optional)
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600"
                  placeholder="John Doe"
                />
              </div>
            </div>

            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600"
                  placeholder="you@example.com"
                />
              </div>
            </div>

            {/* Password */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600"
                  placeholder="••••••••"
                />
              </div>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-indigo-600"
              >
                Sign up
              </button>
            </div>
          </form>

          {/* Divider */}
          <div className="mt-6 relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-500">Or continue with</span>
            </div>
          </div>

          {/* Google Signup Button */}
          <div className="mt-6">
            <button
              type="button"
              onClick={handleGoogleSignup}
              className="flex w-full items-center justify-center gap-2 rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-700 outline outline-1 outline-gray-300 hover:bg-gray-50"
            >
              <svg
                className="h-5 w-5"
                aria-hidden="true"
                viewBox="0 0 488 512"
                fill="currentColor"
              >
                {/* Google Icon Path */}
                <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.2 0 123 24.2 166.3 64.9l-67.5 64.9C306.5 117.6 261.3 96 248 96c-78.3 0-142 63.7-142 142s63.7 142 142 142c45.4 0 86.4-16.5 117.8-43.9l-60.3-56.9C258.5 349.9 224 364 248 364c53 0 96-43 96-96s-43-96-96-96c-29.7 0-56.6 11.9-76.7 31.3l-60.4-56.4C125.8 78.9 185.1 64 248 64c123.7 0 224 100.3 224 224s-100.3 224-224 224c-122.7 0-224-99.6-224-224S125.3 8 248 8c66.2 0 123 24.2 166.3 64.9l-67.5 64.9C306.5 117.6 261.3 96 248 96c-78.3 0-142 63.7-142 142s63.7 142 142 142c45.4 0 86.4-16.5 117.8-43.9l-60.3-56.9C258.5 349.9 224 364 248 364c53 0 96-43 96-96s-43-96-96-96c-29.7 0-56.6 11.9-76.7 31.3l-60.4-56.4C125.8 78.9 185.1 64 248 64c123.7 0 224 100.3 224 224z" />
              </svg>
              Sign up with Google
            </button>
          </div>

          {/* Login Link */}
          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{' '}
            <Link to="/login" className="font-semibold text-indigo-600 hover:text-indigo-500">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
