import React, { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { CheckCircle, MessageSquare } from "lucide-react";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function Lessons() {
  const { course, loading, error, userProgress } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const sectionId = searchParams.get("sectionId");

  const section = course?.sections?.find((sec) => sec.id === sectionId);

  const sortedLessons = useMemo(() => {
    if (!section || !section.lessons) return [];
    return [...section.lessons].sort((a, b) => a.order - b.order);
  }, [section]);

  if (loading)
    return (
      <div className="container mx-auto px-4 py-6 text-center text-sm">
        Loading...
      </div>
    );

  if (error)
    return (
      <div className="container mx-auto px-4 py-6 text-center text-red-600 text-sm">
        Error: {error.message}
      </div>
    );

  if (!course || !course.sections)
    return (
      <div className="container mx-auto px-4 py-6 text-center text-sm">
        No data available.
      </div>
    );

  if (!section) {
    return (
      <div className="container mx-auto px-4 py-6 text-center">
        <p className="text-red-600 text-sm">
          Section not found. Please check the URL or go back.
        </p>
      </div>
    );
  }

  const completedLessons = userProgress[sectionId]?.length || 0;
  const totalLessons = section.lessons.length;

  const handleLessonClick = (lessonId) => {
    navigate(`/lesson-view?lessonId=${lessonId}&sectionId=${sectionId}`);
  };

  // Calculate the percentage for the circle progress
  const percentCompleted = Math.round((completedLessons / totalLessons) * 100);

  // Define radius and related calculations
  const radius = 35;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentCompleted / 100) * circumference;

  return (
    <>
      {/* Header */}
      <header className="bg-white text-gray-800 border-b border-gray-300">
        <div className="flex justify-between items-center px-6 py-4 max-w-7xl mx-auto">
          <ArrowLeftIcon
            className="h-5 w-5 text-black cursor-pointer"
            onClick={() => navigate(-1)}
          />
          {/* <h1 className="text-2xl font-bold tracking-tight">Sat Mastery</h1> */}
          <div className="flex items-center gap-2">
            <span className="text-sm">
              {completedLessons}/{totalLessons}
            </span>
            <div className="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
              <div
                className="bg-teal-500 h-full rounded-full"
                style={{
                  width: `${(completedLessons / totalLessons) * 100}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </header>

      {/* Section Info */}
      <section className="container mx-auto px-6 py-6 max-w-3xl mt-4">
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between">
            {/* Left: Title and Description */}
            <div>
              <h3 className="text-xl font-bold mb-2">{section.title}</h3>
              <p className="text-gray-600">{section.description}</p>
            </div>

            {/* Right: Circle Progress Bar */}
            <div className="relative w-20 h-20">
              <svg className="w-full h-full transform -rotate-90">
                {/* Background circle */}
                <circle
                  cx="40"
                  cy="40"
                  r={radius}
                  className="stroke-gray-200"
                  fill="transparent"
                  strokeWidth="6"
                />
                {/* Progress circle */}
                <circle
                  cx="40"
                  cy="40"
                  r={radius}
                  className="stroke-teal-500"
                  fill="transparent"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeDasharray={circumference}
                  strokeDashoffset={strokeDashoffset}
                  style={{
                    transition: 'stroke-dashoffset 0.5s ease',
                  }}
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center text-sm font-bold">
                {percentCompleted}%
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Lessons List */}
      <div className="container mx-auto px-4 py-6 max-w-3xl">
        <h2 className="text-gray-900 text-lg font-bold mb-4">Lessons</h2>
        {/* <hr className="border-t border-gray-300 mb-6" /> */}

        <ul className="space-y-4">
          {sortedLessons.map((lesson, index) => {
            const isCompleted =
              userProgress[sectionId]?.includes(lesson.id) || false;

            return (
              <li
                key={lesson.id}
                className={`flex items-center justify-between p-4 rounded-lg border ${
                  isCompleted ? "border-black" : "border-gray-200"
                } bg-white hover:shadow-md transition-shadow cursor-pointer`}
                onClick={() => handleLessonClick(lesson.id)}
              >
                <div className="flex items-center gap-4">
                  <span
                    className={`text-sm font-bold ${
                      isCompleted ? "text-black" : "text-gray-600"
                    }`}
                  >
                    {String(index + 1).padStart(2, "0")}
                  </span>
                  <h2 className="text-lg font-bold text-gray-800">
                    {lesson.title}
                  </h2>
                </div>
                <div className="flex items-center gap-4">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-semibold ${
                      isCompleted
                        ? "bg-gray-100 text-gray-700"
                        : "bg-gray-200 text-gray-800"
                    }`}
                  >
                    {lesson.type}
                  </span>
                  {isCompleted && (
                    <CheckCircle className="text-black w-5 h-5" />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
