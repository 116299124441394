import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { LockClosedIcon } from "@heroicons/react/24/outline"; // Import LockClosedIcon
import { CheckCircle } from "lucide-react"; // Optional: For completed sections

export default function Learn() {
  const { course, loading, error, userProgress } = useContext(DataContext);
  const navigate = useNavigate();

  // Handle loading / error states
  if (loading)
    return <div className="container mx-auto px-4 py-6 text-center text-sm">Loading...</div>;
  if (error)
    return (
      <div className="container mx-auto px-4 py-6 text-center text-red-600 text-sm">
        Error: {error.message}
      </div>
    );
  if (!course)
    return (
      <div className="container mx-auto px-4 py-6 text-center text-sm">
        No SAT course data found.
      </div>
    );

  // Sort sections by `order`
  const sortedSections = course.sections?.sort((a, b) => a.order - b.order);

  // Navigate to Lessons page with the section ID
  const handleSectionClick = (sectionId, locked) => {
    if (!locked) {
      navigate(`/dashboard/lesson?sectionId=${sectionId}`);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="space-y-4 max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-900 mt-8 mb-6">Sections</h1>
        <hr className="border-gray-300 my-4" />
        {sortedSections.map((section, index) => {
          // Calculate progress for the section
          const completedLessons = userProgress[section.id]?.length || 0;
          const totalLessons = section.lessons.length;
          const percentCompleted = totalLessons > 0 ? Math.round((completedLessons / totalLessons) * 100) : 0;

          // Define radius and related calculations for SVG
          const radius = 35;
          const circumference = 2 * Math.PI * radius;
          const strokeDashoffset = circumference - (percentCompleted / 100) * circumference;

          return (
            <div
              key={section.id}
              className={`flex justify-between items-center border rounded-lg shadow-md p-4 ${
                section.locked
                  ? "bg-gray-100 border-gray-300 cursor-not-allowed"
                  : "bg-white border-gray-200 cursor-pointer"
              }`}
              onClick={() => handleSectionClick(section.id, section.locked)}
            >
              {/* Left Content: Title, Description, and Button */}
              <div className="flex-1">
                <h2 className="text-lg font-bold text-gray-800 mb-1">
                  {index + 1}. {section.title}
                </h2>
                <p className="text-gray-600 text-xs">
                  {section.description || `Order: ${section.order}`}
                </p>

                {/* Button below the description */}
                <div className="mt-4">
                  {section.locked ? (
                    <div className="flex items-center gap-2">
                      <LockClosedIcon className="w-5 h-5 text-gray-500" />
                      <button
                        disabled
                        className="bg-gray-300 text-gray-600 py-1.5 px-3 rounded-md text-sm cursor-not-allowed"
                      >
                        Locked
                      </button>
                    </div>
                  ) : (
                    <button
                    onClick={() => handleSectionClick(section.id, section.locked)}
                    className="bg-gray-700  text-white py-1.5 px-3 rounded-md text-sm hover:bg-gray-800"
                  >
                    Continue
                  </button>
                  )}
                </div>
              </div>

              {/* Right Content: SVG Progress Circle */}
              <div className="relative w-20 h-20">
                <svg className="w-full h-full transform -rotate-90" viewBox="0 0 80 80">
                  {/* Background circle */}
                  <circle
                    cx="40"
                    cy="40"
                    r={radius}
                    className="stroke-gray-200"
                    fill="transparent"
                    strokeWidth="6"
                  />
                  {/* Progress circle */}
                  <circle
                    cx="40"
                    cy="40"
                    r={radius}
                    className="stroke-teal-500"
                    fill="transparent"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    style={{
                      transition: 'stroke-dashoffset 0.5s ease',
                    }}
                  />
                </svg>
                <div className="absolute inset-0 flex items-center justify-center text-sm font-bold">
                  {percentCompleted}%
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
