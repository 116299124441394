import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import EmbeddedCheckoutButton from '../components/EmbeddedCheckoutButton';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';

const Store = () => {
  const { hasPaid } = useContext(AuthContext);

  return (
    <div className="min-h-screen bg-white p-6 text-sm">
      {/* Fixed X at the top-right */}
      <Link
        to="/dashboard/learn"
        className="fixed top-4 right-4 p-2 z-50"
      >
        <X className="w-6 h-6 text-gray-500 hover:text-gray-700" />
      </Link>

      <div className="max-w-4xl mx-auto pt-16">
        <h1 className="text-3xl font-semibold text-center mb-8">
          Save Hours and get Perfect Scores.
        </h1>
        
        {/* Plan Toggle */}
        <div className="flex justify-center mb-8">
          <div className="inline-flex rounded-full bg-gray-100 p-0.5">
            <button className="px-4 py-1 rounded-full bg-white shadow-sm text-sm">
              Monthly
            </button>
            <button className="px-4 py-1 text-gray-500 text-sm">
              Yearly
            </button>
          </div>
        </div>

        {/* Plans Container */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Starter Plan */}
          <div className="border border-gray-200 rounded-lg p-6 bg-white">
            <h2 className="text-xl font-bold text-gray-900 mb-2">Starter</h2>
            <div className="flex items-baseline mb-4">
              <span className="text-xl font-semibold">$</span>
              <span className="text-2xl font-semibold">0</span>
              <span className="text-gray-500 ml-1">USD/month</span>
            </div>
            <p className="text-gray-600 mb-6">
              Level up productivity and creativity with expanded access
            </p>
            
            {hasPaid ? (
              <button className="w-full py-2 mb-6 rounded-full bg-gray-100 text-gray-800 font-medium">
                Your current plan
              </button>
            ) : (
              <button className="w-full py-2 mb-6 rounded-full bg-gray-200 text-gray-800 font-medium hover:bg-gray-300">
                Current Plan 
              </button>
            )}

            <ul className="space-y-3">
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>Everything in Free</span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>
                  Extended limits on messaging, file uploads, advanced data analysis, and image generation
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>Everything in Free</span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>
                  Extended limits on messaging, file uploads, advanced data analysis, and image generation
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>Everything in Free</span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>
                  Extended limits on messaging, file uploads, advanced data analysis, and image generation
                </span>
              </li>
            </ul>
          </div>

          {/* Pro Plan */}
          <div className="border border-gray-200 rounded-lg p-6 bg-white">
            <h2 className="text-xl font-bold text-gray-900 mb-2">Pro</h2>
            <div className="flex items-baseline mb-4">
              <span className="text-xl font-semibold">$</span>
              <span className="text-2xl font-semibold">20</span>
              <span className="text-gray-500 ml-1">USD/month</span>
            </div>
            <p className="text-gray-600 mb-6">
              Get the perfect SAT score and save hours of work.
            </p>
            
            {/* Embedded checkout button (black) */}
            <EmbeddedCheckoutButton />

            <ul className="space-y-3">
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>Everything in Starter</span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>
                  Unlimited access to live sessions and content
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>Unlimited access to workshops</span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>Unlimited access to AI Chat and Models</span>
              </li>
              <li className="flex items-start">
                <span className="text-gray-800 mr-2">✓</span>
                <span>Unlimited access to course material</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
