import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Signup from './auth/Signup';
import Login from './auth/Login';
import Dash from './dashboard/Dash';
import Learn from './dashboard/Learn';
import Live from './dashboard/Live';
import { DataProvider } from './context/DataContext';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Lessons from './dashboard/Lessons';
import LessonView from './pages/LessonView';
import Store from './store/Store'
import SuccessPage from './pages/Success';
import CancelSubscription from './pages/Cancel'
import Settings from './pages/Settings';
import Solver from './dashboard/Solver';
import { ChatProvider } from './context/ChatContext';
import { MathJaxContext } from 'better-react-mathjax';

function App() {
  return (
    <MathJaxContext>
      <AuthProvider>
        <ChatProvider>
          <DataProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />

                {/* Protected Dashboard Routes */}
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dash />
                    </ProtectedRoute>
                  }
                >
                  <Route path="learn" element={<Learn />} />
                  <Route path="live" element={<Live />} />
                  <Route path="lesson" element={<Lessons />} />
                  <Route path="solver" element={<Solver />} />
                </Route>

              {/* Independent route for lesson detail page */}
              <Route path="/lesson-view" element={<LessonView />} />
               <Route path="/store" element={<Store />} />
                 <Route path="/success" element={<SuccessPage />} />
                   <Route path="/cancel" element={<CancelSubscription />} />
                <Route path="/settings" element={<Settings />} />
            </Routes>
          </DataProvider>
        </ChatProvider>
      </AuthProvider>
    </MathJaxContext>
  );
}

export default App;
